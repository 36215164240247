
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































.members-grid {
  @include default-margin;
}

.members-grid-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.members-grid__header {
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.members-grid__title {
  color: $c-green-dark;

  .home & {
    font-size: 2.4rem;
    line-height: 1.4;

    @include mq(l) {
      font-size: 2.8rem;
    }

    @include mq(xxl) {
      font-size: 3.6rem;
    }
  }
}

.members-grid__text {
  color: $c-green-dark;

  @include mq($until: m) {
    margin-top: $spacing;
  }

  @include mq(m) {
    width: col(9);
    padding-right: col(2);
  }
}

.members-grid__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    margin: 0 col(1);
  }

  // stylelint-disable-next-line
  .members-grid__header + & {
    margin-top: $spacing * 2;

    @include mq(m) {
      margin-top: $spacing * 5;
    }
  }
}

.members-grid__item {
  position: relative;
  width: 100%;

  @include mq($until: xs) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(xs, s) {
    width: calc(50% - #{$spacing * 2 / 2});

    &:nth-child(2n + 2) {
      margin-left: $spacing * 2;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s, l) {
    width: calc(33.33% - #{$spacing * 3 * 2 / 3});

    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $spacing * 3;
    }

    &:nth-child(n + 4) {
      margin-top: $spacing * 3;
    }
  }

  @include mq(l) {
    width: calc(25% - #{$spacing * 4 * 3 / 4});

    &:nth-child(4n + 2),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: $spacing * 4;
    }

    &:nth-child(n + 5) {
      margin-top: $spacing * 4;
    }
  }
}

.members-grid__item__picture-outer {
  position: relative;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0FF' stroke-width='4' stroke-dasharray='2%2c 15' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  &::before {
    content: '';
    display: block;
    padding-top: calc(18 / 25 * 100%);
  }
}

.members-grid__item__picture,
[class*='members-grid__item__picture--'] {
  @include image-fit(contain, center);

  top: $spacing * 2;
  left: $spacing * 2;
  width: calc(100% - #{$spacing * 4});
  height: calc(100% - #{$spacing * 4});

  &[class*='--placeholder'] {
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 100%;
  }
}

.members-grid__all {
  margin-top: $spacing * 3;
  margin-left: col(1);

  .home & {
    margin-right: col(1);
    margin-left: auto;
  }
}

.members-grid__item__link {
  @extend %text-nodecoration;
  @include get-all-space;
}
