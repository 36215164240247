
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.regions-switcher {
  @extend %ff-alt;
  @extend %fw-bold;

  position: relative;
  width: 140px;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.regions-switcher__display {
  position: relative;
  padding: $spacing / 2 0;
  color: $c-green-dark;
  border-bottom: 2px solid $c-green-light;
  transition: color 0.1s ease-out, border-color 0.1s ease-out;
  cursor: pointer;
  user-select: none;

  &:hover,
  .regions-switcher.is-open & {
    color: $c-green-light;
  }
}

.regions-switcher__display__arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
  fill: $c-green-dark;
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%) rotate(0);
  transition: fill 0.2s ease-out, transform 0.2s ease-out;
  will-change: transform;

  .regions-switcher.is-open & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.regions-switcher__display__label {
  overflow: hidden;
  padding-right: $spacing * 3;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.regions-switcher__options {
  @include scrollbars;

  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  width: 100%;
  max-height: 300px;
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  border-top: 0;
}

.regions-switcher__options__option {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  padding: 0 $spacing * 2.5 0 $spacing;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $c-white;
    background-color: $c-green-dark;
  }

  &.is-selected {
    color: $c-green-dark;
    background-color: $c-green-light;
    pointer-events: none;
  }
}
