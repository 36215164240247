
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.regions-contents {
  @include default-margin(0.5);
}

.regions-contents__header-inner {
  display: flex;
  justify-content: space-between;
}

.regions-contents__header__switcher-outer {
  margin-left: col(1);
}

.regions-contents__header__share {
  margin-right: col(1);
}

.regions-contents__content {
  margin-top: $spacing * 2;
}
